import React, { Component } from "react";
import { Link } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Col } from "reactstrap";
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import Slider from "react-slick";
import Header from "./header";
import Footer from "./NewFooter";
import WOW from "../../animation/Wow";
//import ReactWOW from 'react-wow'
import request from "../../api/api";
import Cookie_svg from "../../images/home/cookie.svg";
import { toast, ToastContainer } from "react-toastify";
//import Anime from 'react-anime';
import AuthetiCationModal from "./AuthetiCationModal";
import { openPopupWidget } from "react-calendly";

// Images
import homebanner from "../../images/homes-new/home_banner.png"
import pmb_icon1 from "../../images/homes-new/pmb_icon1.png"
import pmb_icon2 from "../../images/homes-new/pmb_icon2.png"
import pmb_icon3 from "../../images/homes-new/pmb_icon3.png"
import pmb_icon4 from "../../images/homes-new/pmb_icon4.png"



import shiftManagement from "../../images/homes-new/features-shiftManagement.png";
import timesheets from "../../images/homes-new/features-timesheets.png";
import management from "../../images/homes-new/features-management.png";
import invoices from "../../images/homes-new/features-invoices.png";
import compliance from "../../images/homes-new/features-compliance.png";

import recruitment from "../../images/homes-new/features-recruitment.png";

import vilot_line from "../../images/homes-new/vilot-line.png"

import growth1 from "../../images/homes-new/agency.png"
import growth2 from "../../images/homes-new/user.png"
import growth3 from "../../images/homes-new/shiftbooked.png"
import growth4 from "../../images/homes-new/hoursworked.png"
import plain from "../../images/homes-new/plain.png"

import chat from "../../images/homes-new/chat.png"

import adopting_user1 from "../../images/homes-new/adopting-user1.png"
import adopting_user2 from "../../images/homes-new/adopting-user2.png"
import adopting_user3 from "../../images/homes-new/adopting-user3.png"
import adopting_user4 from "../../images/homes-new/adopting-user4.png"

import adopting_slide1 from "../../images/homes-new/adopting-slide1.png"
import adopting_slide2 from "../../images/homes-new/adopting-slide2.png"
import adopting_slide3 from "../../images/homes-new/adopting-slide3.png"
import adopting_slide4 from "../../images/homes-new/adopting-slide4.png"

import ap_card1 from "../../images/homes-new/ataat.png"
import ap_card2 from "../../images/homes-new/schedule.png"
import ap_card3 from "../../images/homes-new/reduce.png"
import ap_card4 from "../../images/homes-new/gps.png"
import ap_card5 from "../../images/homes-new/savetime.png"

import app_iphone from "../../images/homes-new/app_phone.png"
import play_store from "../../images/homes-new/play-store.png"
import app_store from "../../images/homes-new/app-store.png"

import quoteT from "../../images/quote.svg"
import quoteB from "../../images/quote.svg"

import shift_schedule from "../../images/homes-new/shift-schedule.png"
import online_time_attendance from "../../images/homes-new/online-time-attendance.png"
import centralised_timesheets from "../../images/homes-new/centralised-timesheets.png"
import { BookDemoButton } from "./Features";

let pageSettings = {
	backgroundColor: 'ffffff',
	hideEventTypeDetails: false,
	hideLandingPageDetails: false,
	primaryColor: '00a2ff',
	textColor: '4d5055'
},
	// url = 'https://calendly.com/agencyportal',
	url = 'https://calendly.com/d/cks-3hf-fbt/demo?month=2022-02',
	prefill = {},
	utm = {};


export default class NewHomePage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: "1",
			activeTabs: "1",
			email: "",
			showLoginModal: false,
			autheticateTab: 1,
			cookie_acceptance: false,
			cookie_terms: true,
		};
		this.sliderRef  = null
	}

	


	componentDidMount() {
		window.scrollTo(0, 0);
		new WOW().init();
		const cookie_accepted = JSON.parse(localStorage.getItem('Cookie_acceptance'));
		if (cookie_accepted) {
			this.setState({ cookie_acceptance: cookie_accepted });
		} else {
			this.setState({ cookie_acceptance: false });
		}
		// const subdomain = window.location.host.split(".")[1] ? window.location.host.split(".")[0] : false;
		// if (subdomain !== "www") {
		// 	window.location = "/agency-login";
		// }
		const subdomain = window.location.host.split(".")[1] ? window.location.host.split(".")[0] : false;

		if (subdomain !== "www") { return this.props.history.push("/agency-login") }

		const arrbtn = document.getElementById("commonArrow");
		//const healist = document.getElementById("hedspace");
		window.onscroll = function () {
			scrollFunction();
		};
		function scrollFunction() {
			if (
				document.body.scrollTop > 20 ||
				document.documentElement.scrollTop > 20
			) {
				arrbtn.style.display = "block";

			} else {
				arrbtn.style.display = "none";

			}
		}
	}

	toggle = activeTab => {
		this.setState({ activeTab: activeTab });
		if(this.sliderRef){
			this.sliderRef.slickGoTo(+activeTab)
		}
	};
	toggles = activeTabs => {
		this.setState({ activeTabs: activeTabs });
	};
	toggles = activeTabs => {
		const activeSlideIndex = parseInt(activeTabs, 10) - 1;
		this.setState({ activeTabs, activeSlideIndex });
		if(this.sliderRef){
			this.sliderRef.slickGoTo(+activeSlideIndex)
		}
	  };
	  
	menufun = () => {
		document.getElementById("varmenu").classList.add("shower-menus");
		document.getElementById("shbody").classList.add("shower-body");
	};
	hidingmenu = () => {
		document.getElementById("varmenu").classList.remove("shower-menus");
		document.getElementById("shbody").classList.remove("shower-body");
	};
	clomenus = () => {
		document.getElementById("varmenu").classList.remove("shower-menus");
		document.getElementById("shbody").classList.remove("shower-body");
	};
	upperFun = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	};
	onChange = e => {
		this.setState({
			email: e.target.value
		});
	};
	ValidateEmail(mail) {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return true;
		}
		return false;
	}
	onTryFree = e => {
		const { email } = this.state;
		if (email === "") {
			toast.error("Please enter Email");
		} else if (this.ValidateEmail(email) === false) {
			toast.error("You have entered an invalid email address!");
		} else {
			request({
				url: "/site/submit/try_for_free",
				method: "POST",
				data: {
					email: this.state.email
				}
			}).then(res => {
				/* this.props.history.push({
					pathname: "/agency-register",
					state: {
						email: this.state.email
					}
				}) */
				this.setState({ 'autheticateTab': '2' })
				localStorage.setItem('tryforfree_email', this.state.email);
				this.setState({ 'showLoginModal': true });
			})
		}
	};

	openSigninModal = (e, tab) => {
		this.setState({ 'showLoginModal': true });
		this.setState({ 'autheticateTab': tab })
	};

	closeSigninModal = (e) => {
		this.setState({ 'showLoginModal': false });
	}
	openInNewTab = (url) => {
		window.open(url, '_blank', 'noreferrer');
	}

	onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });
	cookieAcceptance = () => {
		this.setState({ cookie_acceptance: true });
		localStorage.setItem('Cookie_acceptance', true)
	}
	render() {
		const settings = {
			dots: false,
			infinite: true,
			speed: 1500,
			slidesToShow: 10,
			slidesToScroll: 1,
			autoplay: true,
			// speed: 1000,
			smartSpeed: 1000,
			autoplaySpeed: 1000,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 8,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
						infinite: true
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1
					}
				}
			]
		};

		const shiftslider = {
			dots: false,
			infinite: true,
			speed: 1500,
			slidesToShow: 1,
			slidesToScroll: 1,
			// autoplay: true,
			// speed: 1000,
			accesibility: false,
            draggable: false,
            swipe: false,
            touchMove: false,
			smartSpeed: 1000,
			autoplaySpeed: 1000,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};

		const multisliders = {
			dots: true,
			infinite: true,
			speed: 1000,
			centerMode: true,
			slidesToShow: 2,
			touchMove: true,
			accesibility: false,
            draggable: false,
			margin: 10,
			centerPadding: '10px',
			slidesToScroll: 1,
			autoplay: true,
			arrows: false,
			smartSpeed: 3000,
			autoplaySpeed: 3000,
			
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};
		return (
			<>
			  	<MetaTags>
					<title>Shift and Employee Management Software - Getshifts</title>
					<meta
					name="keyword"
					content="best shift management software, shift management software for employee,online shift management software, shift Scheduling software"
					/>
					<meta
					name="description"
					content="Discover the ultimate suite of tools for Strong business operations with compliance, recruitment, invoicing, employee, timesheet, and shift management software. Streamline your processes efficiently and effectively with our all-inclusive solutions"
					/>
				</MetaTags>
				<section className="cover-banners">
					{/* {this.state.cookie_acceptance === false && this.state.cookie_terms && (
						<div className="cookie">
							<div className="cookie-img">
								<img src={Cookie_svg} alt="cookie_img" />
								<p>We use cookie to personalize your experience. By continuing to visit this website you agree to our use of cookies.</p>
								<button onClick={() => this.cookieAcceptance()}>Got it</button>
								<span className="close-but">
									<i className="fa fa-times" onClick={() => this.setState({ cookie_terms: false })} aria-hidden="true"></i>
								</span>
							</div>
						</div>
					)} */}
					<Header id="hedspace"></Header>
					{/* <ToastContainer position="top-right" autoClose={2500} closeOnClick /> */}

					<section className="home-banner">
						<div className="container">
							<div className="banner-parts">
								<div >
									<div className="left-banner">
										<h4 className="sht-list1"> Shift and <br /> Staff Management</h4>

										<h6> Your Complete Agency Management Software </h6>
										<p>
											Automate Your Business With Everything You Need in One Powerful Platform
										</p>
											<div className="try-free book_a_demo_view">
											{/* <Link to="/viewdemo" className="try-free-view">
												View Demo
											</Link> */}
											<button type="button" className="btn tryforfree">
												<Link to="/signup">Get started for free</Link>
											</button>
											{/* <button type="button" className="btn tryforfree book_a_demo_btn">
												<Link to="#" onClick={()=>window.onBookDemo()} >Book a Demo</Link>
											</button> */}
											<BookDemoButton />
			
											{/* <button type="button" className="btn tryforfree" onClick={e => this.openSigninModal(e, '2')}	>
												{" "}Get Started - <span style={{ fontSize: "12px" }}>It's free</span></button> */}


										</div>
									</div>
								</div>
								<div className="right_banners">
									<img src={homebanner} alt="homebanner" />
								</div>
							</div>


						</div>
					</section>
				</section>

				{/* powerful_manage_business  Start */}
				<section className="powerful_manage_business pmb">
					<div className="container">
						<div className="pmb_sec">
							<h6>the powerful new way to manage your business</h6>
							<div className="rowed mt-3">
								<div className="sec1 powersec ttc">
									<img src={pmb_icon1} alt="simply staff managenent" />
									<span>simply staff managenent</span>

								</div>
								<div className="sec2 powersec ttc">
									<img src={pmb_icon2} alt="real time-updates" />
									<span>real time-updates</span>

								</div>
								<div className="sec3 powersec ttc">
									<img src={pmb_icon3} alt="save time and money" />
									<span>save time and money</span>

								</div>
								<div className="sec4 powersec ttc">
									<img src={pmb_icon4} alt="automated time sheet and invoices" />
									<span>automated time sheet and invoices</span>

								</div>
							</div>
						</div>
					</div>
				</section>
				{/* powerful_manage_business  End */}

				<section className="tabbing-scroll m-4">
					<div className="container">
						<div className="tab-lists-scroll">
							<Nav tabs >
								<NavItem>
									<NavLink className={classnames({active: this.state.activeTabs === "1"})} onClick={() => {this.toggles("1");}}>
										<div className="multi-tabs">
											<div className="right-dets">
												<div className="imag_tittle">
													<img src={`${process.env.PUBLIC_URL}/img/home/clr-shfmang.svg`} alt="img" />
													<h4>Shift Management</h4>
												</div>
												<div className="head_content">
													<img src={vilot_line} alt="vilot_line" />
													<h5>Effortless Shift Management</h5>
													<p>Streamline staff scheduling with Get Shifts Shift Management module; the central, most powerful and intuitive module of the Get Shifts platform that lets you create, edit and view all your staff shifts and schedules online.</p>
												</div>

											</div>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={classnames({active: this.state.activeTabs === "2"})} onClick={() => {this.toggles("2");}}>
										<div className="multi-tabs">
											<div className="right-dets">
												<div className="imag_tittle">
													<img src={`${process.env.PUBLIC_URL}/img/home/clr-timesheet.svg`} alt="img" />
													<h4> Timesheets </h4>
												</div>
												<div className="head_content">
													<img src={vilot_line} alt="vilot_line" />
													<h5>Automated Timesheets</h5>
													<p>Drop all human errors with automated timesheets based on the scheduled, completed and approved shifts with your chosen billing periods ready to export for Payroll.</p>
												</div>
											</div>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={classnames({active: this.state.activeTabs === "3"})} onClick={() => {this.toggles("3");}}>
										<div className="multi-tabs">


											<div className="right-dets">

												<div className="imag_tittle">
													<img src={`${process.env.PUBLIC_URL}/img/home/clr-employee.svg`} alt="img" />
													<h4>Employee Management </h4>
												</div>
												<div className="head_content head_content_alt">
													<img src={vilot_line} alt="vilot_line" />

													<h5>Manage employees with ease</h5>
													<p>Take control of your entire staff database in one intuitive portal: view, edit and update staff information with a few clicks.</p>

												</div>
											</div>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={classnames({active: this.state.activeTabs === "4"})} onClick={() => {this.toggles("4");}}>
										<div className="multi-tabs">

											<div className="right-dets">

												<div className="imag_tittle">
													<img src={`${process.env.PUBLIC_URL}/img/home/clr-invoice.svg`} alt="img" />
													<h4>Invoicing</h4>
												</div>
												<div className="head_content head_content_alt">
													<img src={vilot_line} alt="vilot_line" />
													<h5>Automated Invoicing</h5>
													<p>Generating invoices automatically when timesheets are approved ensures timely payment with less pain, all in one platform.</p>
												</div>
											</div>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={classnames({active: this.state.activeTabs === "5"})} onClick={() => {this.toggles("5");}}>
										<div className="multi-tabs">

											<div className="right-dets">

												<div className="imag_tittle">
													<img src={`${process.env.PUBLIC_URL}/img/home/clr-compliance.svg`} alt="img" />
													<h4>Compliance </h4>
												</div>
												<div className="head_content head_content_alt">
													<img src={vilot_line} alt="vilot_line" />
													<h5>Worry-Free Compliance</h5>
													<p>Stay on top of staff compliance and enjoy the peace of mind of centralised record keeping. With Get Shifts, your employees information is digitally stored and organised. All essential documents and information for your entire staff database. Employment eligibility - accurate, compliant, and stress-free.</p>
												</div>
											</div>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={classnames({active: this.state.activeTabs === "6"})} onClick={() => {this.toggles("6");}}>
										<div className="multi-tabs">

											<div className="right-dets">

												<div className="imag_tittle">

													<img src={`${process.env.PUBLIC_URL}/img/home/clr-recuirement.svg`} alt="img" />
													<h4>Recruitment Management </h4>

												</div>

												<div className="head_content head_content_alt">
													<img src={vilot_line} alt="vilot_line" />
													<h5>Improved Recruitment Processes</h5>
													<p>Hire new employees within minutes, not days. Get Shifts will help you organise your recruiting process and save time by eliminating spreadsheets and busywork. Attract better candidates, track applicants and manage the recruitment process smoothly, helping you stay ahead in this sector with high staff turnover.</p>
												</div>
											</div>
										</div>
									</NavLink>
								</NavItem>
							</Nav>
							<Nav tabs className="slide-tabbing">
								<NavItem>
									<NavLink className={classnames({active: this.state.activeTabs === "1"})}onClick={() => {this.toggles("1");}}>
										<div className="crls-sec">
											<span> </span>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={classnames({active: this.state.activeTabs === "2"})} onClick={() => {this.toggles("2");}}>
										<div className="crls-sec">
											<span> </span>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={classnames({active: this.state.activeTabs === "3"})} onClick={() => {this.toggles("3");}}>
										<div className="crls-sec">
											<span> </span>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={classnames({active: this.state.activeTabs === "4"})} onClick={() => {this.toggles("4");}}>
										<div className="crls-sec">
											<span> </span>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={classnames({active: this.state.activeTabs === "5"})} onClick={() => {this.toggles("5");}}>
										<div className="crls-sec">
											<span> </span>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									
									<NavLink className={classnames({active: this.state.activeTabs === "6"})} onClick={() => {this.toggles("6");}}>
										<div className="crls-sec">
											<span> </span>
										</div>
									</NavLink>
								</NavItem>
							</Nav>
							
							<TabContent activeTab={"1"} className="sldd-parts">
								<TabPane tabId="1" className="">
									<div className="show-gals">
								
										<Slider onSwipe={(...e)=>console.log('swipe' , e)} {...shiftslider} ref={(slide)=>(this.sliderRef = slide)}  >
											<div className="shift-arrag">
												<img src={shiftManagement} alt="shiftManagement" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={timesheets} alt="timesheets" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={management} alt="management" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={invoices} alt="invoices" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={compliance} alt="compliance" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={recruitment} alt="recruitment" loading="lazy"/>
											</div>
										</Slider>

									</div>
								</TabPane>
								{/* <TabPane tabId="2" className="">
									<div className="show-gals">
									
										<Slider {...shiftslider} activeIndex={this.state.activeSlideIndex}>
											<div className="shift-arrag">
												<img src={shiftManagement} alt="shiftManagement" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={timesheets} alt="timesheets" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={management} alt="management" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={invoices} alt="invoices" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={compliance} alt="compliance" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={recruitment} alt="recruitment" loading="lazy"/>
											</div>
										</Slider>

									</div>
								</TabPane>
								<TabPane tabId="3" className="">
									<div className="show-gals">
										<Slider {...shiftslider} activeIndex={this.state.activeSlideIndex}>
											<div className="shift-arrag">
												<img src={shiftManagement} alt="shiftManagement" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={timesheets} alt="timesheets" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={management} alt="management" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={invoices} alt="invoices" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={compliance} alt="compliance" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={recruitment} alt="recruitment" loading="lazy"/>
											</div>
										</Slider>

									</div>
								</TabPane>
								<TabPane tabId="4" className="">
									<div className="show-gals">
										<Slider {...shiftslider} activeIndex={this.state.activeSlideIndex}>
											<div className="shift-arrag">
												<img src={shiftManagement} alt="shiftManagement" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={timesheets} alt="timesheets" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={management} alt="management" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={invoices} alt="invoices" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={compliance} alt="compliance" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={recruitment} alt="recruitment" loading="lazy"/>
											</div>
										</Slider>
									</div>
								</TabPane>
								<TabPane tabId="5" className="">
									<div className="show-gals">
										<Slider {...shiftslider} activeIndex={this.state.activeSlideIndex}>
											<div className="shift-arrag">
												<img src={shiftManagement} alt="shiftManagement" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={timesheets} alt="timesheets" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={management} alt="management" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={invoices} alt="invoices" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={compliance} alt="compliance" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={recruitment} alt="recruitment" loading="lazy"/>
											</div>
										</Slider>

									</div>
								</TabPane>
								<TabPane tabId="6">
									<div className="show-gals">
										<Slider {...shiftslider} activeIndex={this.state.activeSlideIndex}>
											<div className="shift-arrag">
												<img src={shiftManagement} alt="shiftManagement" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={timesheets} alt="timesheets" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={management} alt="management" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={invoices} alt="invoices" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={compliance} alt="compliance" loading="lazy"/>
											</div>
											<div className="shift-arrag">
												<img src={recruitment} alt="recruitment" loading="lazy"/>
											</div>
										</Slider>
									</div>
								</TabPane> */}
							</TabContent>

						</div>
					</div>
				</section>

				<section className="supercharges-mains">
					<div className="container">
						<div className="growing-charges">
							<div className="">
								<div className="growth-channel">
									<div className="abs-manmel">

										<div className="right-mannel">
											
											<h4> Supercharge your growth <img src={plain} alt="plain" /> </h4>
											<p>Get Shifts frees up a bulk of your time and makes running your business a breeze by
												automating your business and admin processes. </p>
										</div>
									</div>
								</div>
							</div>
							<div className="lists-clients mt-4">
								<div className="clients-cates">
									<img src={growth1} alt="Agencies" />
									<div>
										<h3> 1000+ </h3>
										<p> Businessess </p>
									</div>
								</div>
								<div className="clients-cates">
									<img src={growth2} alt="User" />
									<div>
										<h3> 50,000+ </h3>
										<p> Users </p>
									</div>
								</div>
								<div className="clients-cates">
									<img src={growth3} alt="Shifts Booked" />
									<div>
										<h3> 500,000+ </h3>
										<p> Shifts Booked </p>
									</div>
								</div>
								<div className="clients-cates">
									<img src={growth4} alt="Hours Worked" />
									<div>
										<h3> 1M+ </h3>
										<p> Hours Worked </p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="client-lists">
					<div className="container">
						<div className="clients-mains">

							<Col md="12">
								<div className="our-clients">
									<Slider {...settings}>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/angel.png`} alt="img" />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/delight.png`} alt="img" />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/JHN.png`} alt="img" />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/mighty.png`} alt="img" />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/standards.png`} alt="img" />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/relief.png`} alt="img" />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/angel.png`} alt="img" />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/delight.png`} alt="img" />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/JHN.png`} alt="img" />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/mighty.png`} alt="img" />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/standards.png`} alt="img" />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/relief.png`} alt="img" />
										</div>

									</Slider>
								</div>
							</Col>
						</div>
					</div>
				</section>

				{/* <section className="supercharge-growth" id="expfeature">
					<div className="container">
						<div className="heading-styles">
							<h3> Features </h3>
						</div>
						<div className="topper-ranges">
							<Col md="5">
								<div className="left-sches">
									<img src={`${process.env.PUBLIC_URL}/img/home/shift-schedule.svg`} alt="img" />
									<h5> Shift Scheduling </h5>
									<p>
										One click is all you need to notify employees of new schedule updates by email, text message, or
										push notification. Your staff can use Get Shifts iOS and Android mobile apps to access their
										shift schedules and messages, to manage their availability, and any other information.
									</p>
								</div>
							</Col>
							<Col md="7">
								<div className="right-sches">
									<img src={`${process.env.PUBLIC_URL}/img/home/shift-sch.png`} alt="img" />
								</div>
							</Col>
						</div>

						<div className="topper-ranges">
							<Col md="5">
								<div className="left-sches">
									<img src={`${process.env.PUBLIC_URL}/img/home/online-time.svg`} alt="img" />
									<h5>Online Time and Attendance </h5>
									<p>
										{" "}
										Depending on your needs, employees can clock in and clock out their time using a mobile time
										clock app on iPhone or Android, a phone time clock, a computer time clock, or a kiosk on a
										tablet. Time and Attendance automatically adjusts your employees’ timesheets in accordance
										with your established presets. Then, all you need to do is to approve your timesheets online,
										create invoices and export them to payroll. {" "}
									</p>
								</div>
							</Col>
							<Col md="7">
								<div className="right-sches">
									<img src={`${process.env.PUBLIC_URL}/img/home/online.png`} alt="img" />
								</div>
							</Col>
						</div>

						<div className="topper-ranges">
							<Col md="5">
								<div className="left-sches">
									<img src={`${process.env.PUBLIC_URL}/img/home/timesheets-sch.svg`} alt="img" />
									<h5> Centralised Timesheets for All Your Employees  </h5>
									<p>
										Accelerate your timesheet creation process and prevent unnecessary retranscriptions by making
										time entries easily accessible online to your managers. As needed, you can allow employees to
										access their own timesheets and to add or modify entries from their very own mobile phone app,
										tablet, or computer.

									</p>
								</div>
							</Col>
							<Col md="7">
								<div className="right-sches">
									<img src={`${process.env.PUBLIC_URL}/img/home/timesheet-emp.png`} alt="img" />
								</div>
							</Col>
						</div>

						<div className="started-lists">
							<div className="col-md-12">
								<p> <span className="list-adop"> Don't only listen to us </span>
									Adopting Get Shifts is like having 3 extra hours in a day*
								</p>
								<a href="#" onClick={e => this.openSigninModal(e, '2')}>Get Started - <span> It's free </span> </a>
							</div>

						</div>
					</div>
				</section> */}

				{/* <section className="switch-tabs">
					<div className="container">
						<div className="tabs-accrs">
							<h3> Features </h3>
							<Nav tabs>
								<NavItem className="">
									<NavLink
										className={classnames({
											active: this.state.activeTab === "1"
										})}
										onClick={() => {
											this.toggle("1");
										}}
									>
										<div className="tabs-shss">
											<div className="height-mids">
												<img
													className="bef-tab"
													src={`${process.env.PUBLIC_URL}/img/home/step1-act.png`}
													alt="img"
												/>
												<img
													className="aft-tab"
													src={`${process.env.PUBLIC_URL}/img/home/step1.svg`}
													alt="img"
												/>
											</div>
											<p> Automated Time and Attendance Tracker </p>
										</div>
									</NavLink>
								</NavItem>
								<NavItem className="">
									<NavLink
										className={classnames({
											active: this.state.activeTab === "2"
										})}
										onClick={() => {
											this.toggle("2");
										}}
									>
										<div className="tabs-shss">
											<div className="height-mids">
												<img
													className="bef-tab"
													src={`${process.env.PUBLIC_URL}/img/home/step2.svg`}
													alt="img"
												/>
												<img
													className="aft-tab"
													src={`${process.env.PUBLIC_URL}/img/home/step2-act.png`}
													alt="img"
												/>
											</div>
											<p> Easily Compare Attendance with Work Schedules  </p>
										</div>
									</NavLink>
								</NavItem>
								<NavItem className="">
									<NavLink
										className={classnames({
											active: this.state.activeTab === "3"
										})}
										onClick={() => {
											this.toggle("3");
										}}
									>
										<div className="tabs-shss">
											<div className="height-mids">
												<img
													className="bef-tab"
													src={`${process.env.PUBLIC_URL}/img/home/step3.svg`}
													alt="img"
												/>
												<img
													className="aft-tab"
													src={`${process.env.PUBLIC_URL}/img/home/step3-act.png`}
													alt="img"
												/>
											</div>
											<p> Reduce no shows and Lateness </p>
										</div>
									</NavLink>
								</NavItem>
								<NavItem className="">
									<NavLink
										className={classnames({
											active: this.state.activeTab === "4"
										})}
										onClick={() => {
											this.toggle("4");
										}}
									>
										<div className="tabs-shss">
											<div className="height-mids">
												<img
													className="bef-tab"
													src={`${process.env.PUBLIC_URL}/img/home/step4.svg`}
													alt="img"
												/>
												<img
													className="aft-tab"
													src={`${process.env.PUBLIC_URL}/img/home/step4-act.png`}
													alt="img"
												/>
											</div>
											<p>GPS Time Clock </p>
										</div>
									</NavLink>
								</NavItem>
								<NavItem className="">
									<NavLink
										className={classnames({
											active: this.state.activeTab === "5"
										})}
										onClick={() => {
											this.toggle("5");
										}}
									>
										<div className="tabs-shss">
											<div className="height-mids">
												<img
													className="bef-tab"
													src={`${process.env.PUBLIC_URL}/img/home/step5.svg`}
													alt="img"
												/>
												<img
													className="aft-tab"
													src={`${process.env.PUBLIC_URL}/img/home/step5-act.png`}
													alt="img"
												/>
											</div>
											<p>Save Time</p>
										</div>
									</NavLink>
								</NavItem>

							</Nav>
							<TabContent activeTab={this.state.activeTab}>
								<TabPane tabId="1">
									<div className="tabbings-lists">
										<div className="col-md-6">
											<div className="content-fades">
												<h4> Attendance Management System </h4>
												<p> Employee attendance is automatically tracked in Get Shifts when they clock in clock out. As
													needed, you can set up certain rules to automatically adjust clocked hours.  </p>
											</div>
										</div>
										<div className="col-md-6">
											<div className="img-fades">
												<img src={`${process.env.PUBLIC_URL}/img/home/attendance-tracker.svg`} alt="img"
												/>
											</div>
										</div>
									</div>
								</TabPane>
								<TabPane tabId="2">
									<div className="tabbings-lists">

										<div className="col-md-6">
											<div className="content-fades">
												<h4> Employee Scheduler and Attendance Compatibility </h4>
												<p> Quickly spot discrepancies between scheduled hours and clock in and clock out hours. Make
													better decisions regarding the number of hours to pay; avoid paying for hours that weren’t
													actually worked for. </p>
											</div>
										</div>
										<div className="col-md-6">
											<div className="img-fades">
												<img src={`${process.env.PUBLIC_URL}/img/home/easilycompare-sec.svg`} alt="img"
												/>
											</div>
										</div>
									</div>
								</TabPane>
								<TabPane tabId="3">
									<div className="tabbings-lists">
										<div className="col-md-6">
											<div className="content-fades">
												<h4> Time and Attendance Reminders </h4>
												<p> Late employees are automatically alerted via an attendance notification. You can set the system to
													send you an alert when employees don’t clock in or out, and customise exactly when you and
													your employees should be notified. </p>
											</div>
										</div>
										<div className="col-md-6">
											<div className="img-fades">
												<img src={`${process.env.PUBLIC_URL}/img/home/reduce-sec.svg`} alt="img"
												/>
											</div>
										</div>
									</div>
								</TabPane>
								<TabPane tabId="4">
									<div className="tabbings-lists">
										<div className="col-md-6">
											<div className="content-fades">
												<h4> Employee Time Clock with GPS </h4>
												<p> Define a place and perimeter within which your employees must clock in to record their punch in
													and punch out entries. You can then be sure that they are actually recording their attendance from
													where they are supposed to work.</p>
											</div>
										</div>
										<div className="col-md-6">
											<div className="img-fades">
												<img src={`${process.env.PUBLIC_URL}/img/home/gpsclock-sec.svg`} alt="img"
												/>
											</div>
										</div>
									</div>
								</TabPane>
								<TabPane tabId="5">
									<div className="tabbings-lists">
										<div className="col-md-6">
											<div className="content-fades">
												<h4> Save Time </h4>
												<p> Get Shifts is an employee scheduling software designed specifically to process information
													optimally and accelerate decision-making processes. The app helps you better plan your
													resources and employees.</p>
											</div>
										</div>
										<div className="col-md-6">
											<div className="img-fades">
												<img src={`${process.env.PUBLIC_URL}/img/home/savetime-sec.svg`} alt="img"
												/>
											</div>
										</div>
									</div>
								</TabPane>
								<TabPane tabId="6">
									<div className="tabbings-lists">
										<p>
											Connect your recruitment process through Get Shifts and
											hire new employees with more ease, Get Shifts will help
											you organise your recruitment process and eliminate the
											need for spreadsheets by keeping employee details online.
											Attract better candidates, track applicants and manage the
											recruitment process smoothly, helping you manage a higher
											staff turnover.
										</p>
										<img src={`${process.env.PUBLIC_URL}/img/home/recuriment.jpg`} alt="img" />
									</div>
								</TabPane>
							</TabContent>
						</div>
					</div>
				</section> */}

				<section className="adopting_agency_portal">
					<div className="container">
						<div className="rowed">
							<div className="content">
								<h6>Don't only listen to us</h6>
								<h5>Adopting <strong>Get Shifts</strong> is like having 3 extra hours in an day*</h5>
								<div className="get_chat">
									{/* <button type="button" className="btn tryforfree" onClick={e => this.openSigninModal(e, '2')}	>
										{" "}Get Started - <span style={{ fontSize: "12px" }}>It's free</span></button> */}
									{/* <button type="button" className="btn tryforfree" onClick={e => this.openSigninModal(e, '2')}	>
										<img src={chat} alt="chat" style={{ marginRight: "10px" }} />
										Chat with us</button> */}
									<button type="button" className="btn tryforfree ">
										<Link to="/signup">Get started for free</Link>
									</button>
									{/* <button type="button" className="btn tryforfree book_a_demo_btn">
										<Link to="#" onClick={()=>window.onBookDemo()} >Book a Demo</Link>
									</button> */}
									<BookDemoButton />
									{/* <button type="button" className="btn tryforfree">
										<Link to="/signup"><img src={chat} alt="chat" style={{ marginRight: "10px" }} />Chat with us</Link>
									</button> */}
								
								</div>

							</div>
							<div className="aap_user">
								<div className="app_user">
									<div className="user_info">
										<img src={quoteT} alt="quotes" className="quote-before"/>
										<img src={quoteB} alt="quotes" className="quote-after"/>
										<p>For our small business, Get Shifts' easy invoicing and compliance tools are a godsend, saving time and hassle.</p>
										<h6>Lisa G<span>Agency Owner</span></h6>
									</div>
									
									<div className="user_who">
										<img src={adopting_user1} alt="adopting_user1" />
									</div>
								</div>
								<div className="app_user">
									<div className="user_info">
										<img src={quoteT} alt="quotes" className="quote-before"/>
										<img src={quoteB} alt="quotes" className="quote-after"/>
										<p>Get Shifts revolutionised our temp staffing with superb shift management. Incredibly efficient for our agency.</p>
										<h6>Derek H<span>Temporary Staffing Agency Director</span></h6>
									</div>
									<div className="user_who">
										<img src={adopting_user2} alt="adopting_user2" />
									</div>
								</div>
								<div className="app_user">
									<div className="user_info">
										<img src={quoteT} alt="quotes" className="quote-before"/>
										<img src={quoteB} alt="quotes" className="quote-after"/>
										<p>In recruitment, communication and efficiency are key. Get Shifts excels at both, streamlining our entire process.</p>
										<h6>Raj P<span>Recruitment Agency CEO</span></h6>
									</div>
								
									<div className="user_who">
										<img src={adopting_user3} alt="adopting_user3" />
									</div>
								</div>
								<div className="app_user">
									<div className="user_info">
										<img src={quoteT} alt="quotes" className="quote-before"/>
										<img src={quoteB} alt="quotes" className="quote-after"/>
										<p>As a care agency, tracking recruitment and managing shifts is crucial. Get Shifts makes it seamless and effective.</p>
										<h6>Naomi K<span>Care Agency Manager</span></h6>
									</div>
									<div className="user_who">
										<img src={adopting_user4} alt="adopting_user4" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="exclusive-slider slider">
					<div className="container">
						<div className="app-informations">
							<div className="app-comms">

								<Slider {...multisliders}>
				
									<div className="client-lists excul_lists">
										<div className="client_list_img">
											<img src={shift_schedule} alt="Shift Scheduling" />
										</div>
										<h4>Shift Scheduling</h4>
										<p>One click is all you need to notify employees of new schedule updates by email, text message, or push notification. Your staff can use Get Shifts iOS and Android mobile apps to access their shift schedules and messages, to manage their availability, and any other information.</p>
									</div>
									<div className="client-lists excul_lists excul_lists_alt">
										<div className="client_list_img">
											<img src={online_time_attendance} alt="Online Time and Attendance" />
										</div>
										<h4>Online Time and Attendance</h4>
										<p>Depending on your needs, employees can clock in and clock out their time using a mobile time clock app on iPhone or Android, a phone time clock, a computer time clock, or a kiosk on a tablet. Time and Attendance automatically adjusts your employees’ timesheets in accordance with your established presets. Then, all you need to do is to approve your timesheets online, create invoices and export them to payroll.</p>
									</div>
									<div className="client-lists excul_lists">
										<div className="client_list_img">
											<img src={centralised_timesheets} alt="Centralised Timesheets for All Your Employees" />
										</div>
										<h4>Centralised Timesheets for All Your Employees</h4>
										<p>Accelerate your timesheet creation process and prevent unnecessary retranscriptions by making time entries easily accessible online to your managers. As needed, you can allow employees to access their own timesheets and to add or modify entries from their very own mobile phone app, tablet, or computer.</p>
									</div>

								</Slider>

							</div>
						</div>
					</div>
				</section>


				<section className="five_card">
					<div className="container">
						<div className="card_section">
							<div className="card">
								<img src={ap_card1} alt="ap_card1" />
								<div className="card-hidd">
									<h6>Automated Time And Attendance Tracker</h6>
									<p>Employee attendance is automatically tracked in Get Shifts when they clock in clock out. As needed, you can set up certain rules to automatically adjust clocked hours.</p>
								</div>
							</div>
							<div className="card">
								<img src={ap_card2} alt="ap_card1" />
								<div className="card-hidd">
									<h6>Easily Compare Attendance With Work Schedules</h6>
									<p>Quickly spot discrepancies between scheduled hours and clock in and clock out hours. Make better decisions regarding the number of hours to pay; avoid paying for hours that weren’t actually worked for.</p>
								</div>
							</div>
							<div className="card">
								<img src={ap_card3} alt="ap_card1" />
								<div className="card-hidd">
									<h6>Reduce No-Shows And Lateness</h6>
									<p>Late employees are automatically alerted via an attendance notification. You can set the system to send you an alert when employees don’t clock in or out, and customise exactly when you and your employees should be notified.</p>
								</div>
							</div>
							<div className="card">
								<img src={ap_card4} alt="ap_card1" />
								<div className="card-hidd">
									<h6>GPS Time Clock</h6>
									<p>Define a place and perimeter within which your employees must clock in to record their punch in and punch out entries. You can then be sure that they are actually recording their attendance from where they are supposed to work.</p>
								</div>
							</div>
							<div className="card">
								<img src={ap_card5} alt="ap_card1" />
								<div className="card-hidd">
									<h6>Save Time</h6>
									<p>Get Shifts is an employee scheduling software designed specifically to process information optimally and accelerate decision-making processes. The app helps you better plan your resources and employees.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="exclusive-apps pt-3 mt-4">
					<div className="container">
						<div className="exc-app-info">
							<div className="app-details">
								<h3>Exclusive App For Employees</h3>
								<p>
									Our employee app is robust, full of useful engaging features, giving your staff more control. Some
									of the features include, shift management, where staff can pick up open shifts, request shift
									cancellations as well swap shifts. They can record their exact work hours and attendance by clock
									in and out on the app. The app also includes Availability management, shift and compliance
									reminders.
								</p>
								<div className="view_features_row">
								<BookDemoButton />
								<button className="my-4"><Link to="/application-feature"> View Features <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link></button>
								</div>

							</div>
							<div className="app_phone mt-3">
								<img src={app_iphone} alt="app_iphone" />
							</div>
								<div className="app_play">
									<h6>Get Our Employee App</h6>
										<a href="https://apps.apple.com/us/app/agency-portal/id1484083870" rel="noopener noreferrer" target="_blank">
											<img src={app_store} alt="app Store" />
										</a>
										
										<a href="https://play.google.com/store/apps/details?id=com.agencyportal.com" rel="noopener noreferrer" target="_blank">
											<img src={play_store} alt="Play Store" />
										</a>
								</div>

						</div>
					</div>
				</section>
				{/* <section className="exclusive-apps">
					<div className="container">
						<div className="app-informations">
							<Col md="4">
								<div className="app-details">
									<h3>Exclusive App</h3>
									<h5>For Employees</h5>
									<p>
										{" "}
										Our employee app is robust, full of useful engaging features, giving your staff more control. Some
										of the features include, shift management, where staff can pick up open shifts, request shift
										cancellations as well swap shifts. They can record their exact work hours and attendance by clock
										in and out on the app. The app also includes Availability management, shift and compliance
										reminders.
									</p>
									<div className="dn-section">
										<p> <Link to="/application-feature"> View Features
											<i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link></p>
										<h6>Get the App</h6>
										<ul>
											<li>
												<a href="https://apps.apple.com/us/app/agency-portal/id1484083870" rel="noopener noreferrer" target="_blank">
													{" "}
													<img
														src={`${process.env.PUBLIC_URL}/img/home/app-store.svg`}
														alt="img"
													/>{" "}
												</a>
											</li>
											<li>
												<a href="https://play.google.com/store/apps/details?id=com.agencyportal.com" rel="noopener noreferrer" target="_blank">
													{" "}
													<img
														src={`${process.env.PUBLIC_URL}/img/home/play-store.svg`}
														alt="img"
													/>{" "}
												</a>
											</li>
										</ul>
									</div>
								</div>
							</Col>

							<Col md="8">
								<div className="app-comms">
									<div className="left-shadow"><img src={`${process.env.PUBLIC_URL}/img/home/left-shadow.svg`} /></div>
									<Slider {...multisliders}>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/mbs3.png`} />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/mbs2.png`} />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/mbs4.png`} />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/mb5.png`} />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/mbs1.png`} />
										</div>
										<div className="client-lists">
											<img src={`${process.env.PUBLIC_URL}/img/home/mbs1.png`} />
										</div>
									</Slider>
									<div className="right-shadow"><img src={`${process.env.PUBLIC_URL}/img/home/right-shadow.svg`} /></div>
								</div>
							</Col>
						</div>
					</div>
				</section> */}




				<div className="top-clicks" id="commonArrow" onClick={this.upperFun}>
					<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
				</div>
				{this.state.showLoginModal === true ? <AuthetiCationModal
					loginModal={this.state.showLoginModal}
					closeModal={e => this.closeSigninModal(e)}
					autheticateTab={this.state.autheticateTab}
				/> : null}
				<Footer></Footer>
			</>
		);
	}
}
