import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Header from "./header";
import Footer from "./NewFooter";
import WOW from '../../animation/Wow';
import request, { NodeURL } from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import AuthetiCationModal from "./AuthetiCationModal";

export default class NewPrice extends Component {
	state = {
		planData: [],
		employeeCount: 1,
		cartData: [],
		removedModules: [],
		pricesModels: false,
		moduleModal: false,
		activeFeature: {},
		hr_checked: false,
		showPriceDetail: false,
		showTotalPrice: true,
		showLoginModal: false,
		counter: 5,
		autheticateTab: 1
	};

	componentDidMount() {
		new WOW().init();
		window.scrollTo(0, 0);
		this.blockSpecialCharactersInInput();
		this.planListAPI();
	}

	planListAPI() {
		request({
			url: "/site/subscription/plan-lists",
			method: "GET",
		}).then((res) => {
			if (res.status === 1) {
				this.setState({
					planData: JSON.parse(res.response),
				}, () => {
					this.state.planData.map((item) => {
						if (item.coming_soon === false) {
							this.setState((prevState) => ({
								cartData: [...prevState.cartData, item],
								hr_checked: true
							}));
						}
						if (item.coming_soon === true) {
							this.setState((prevState) => ({
								removedModules: [...prevState.removedModules, item],
							}));
						}
					});
					this.state.planData.map(item => {
						let obj = {};
						obj = item;
						obj.ischecked = true;
						return obj;
					});
				});
			} else if (res.status === 0) {
				toast.error(res.response);
			}
		}).catch((err) => {
			toast.error(err);
		});
	}

	blockSpecialCharactersInInput() {
		// var totalEmployees = document.getElementById("total-employees");
		// Listen for input event on numInput.
		// totalEmployees.onkeydown = function (e) {
		// 	if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8)) {
		// 		return false;
		// 	}
		// };
	}

	onEmployeeChange = e => {
		if (e.target.value.length <= 3) {
			this.setState({ employeeCount: e.target.value });
		}
	};

	onCheckedchange = (e, item, i, name) => {
		e.persist();
		if (name && name === "HR & Compliance Management") {
			this.setState({ hr_checked: !this.state.hr_checked });
		}
		let { cartData } = this.state;
		let index = cartData.findIndex((e) => { return e._id === item._id });
		if (index === -1) {
			cartData.push(item);
			this.setState({ cartData });
		} else {
			cartData.splice(index, 1);
			this.setState({ cartData });
		}
	};

	incrementEmployee = (e) => {
		this.setState({ 'employeeCount': parseInt(this.state.employeeCount) + 1 });
	}

	decrementEmployee = (e) => {
		if (this.state.employeeCount > 1) {
			this.setState({ 'employeeCount': this.state.employeeCount - 1 });
		}
	}

	incrementCounter = e => {
		this.setState({
			counter: this.state.counter + 5,
			additional_counter: this.state.additional_counter + 1
		});
	};

	decrementCounter = e => {
		if (this.state.counter > 5) {
			this.setState(prevState => ({
				counter: prevState.counter - 5,
				additional_counter: this.state.additional_counter - 1
			}));
		}
	};

	openPricesModels = () => {
		this.setState({
			pricesModels: !this.state.pricesModels
		});
	};

	openModuleModal = (e, item) => {
		e.stopPropagation();
		e.preventDefault();
		e.persist();
		this.setState({
			moduleModal: !this.state.moduleModal,
		}, () => {
			if (this.state.moduleModal === true && item && Object.keys(item).length > 0) {
				this.setState({ activeFeature: item });
			} else {
				this.setState({ activeFeature: {} });
			}
		});
	};

	handleRegister = () => {
		this.props.history.push('/agency-register')
	}

	showPriceDetailView = (e) => {
		this.setState({
			showPriceDetail: true,
			showTotalPrice: false
		})
	}

	openSigninModal = (e, tab) => {
		e.preventDefault();
		this.setState({ 'showLoginModal': true });
		this.setState({ 'autheticateTab': tab })
	};

	closeSigninModal = (e) => {
		this.setState({ 'showLoginModal': false });
	}

	checkdivitem = (e, item) => {
		let { planData } = this.state;
		planData.map((it) => {
			let obj = {};
			obj = it;
			if (String(it._id) === String(item._id)) {
				obj.ischecked = !it.ischecked;
			}
			return obj;
		});
		this.setState({ planData });
	};

	render() {
		const { planData, cartData, activeFeature } = this.state;
		let priceArr;
		let total = 0;
		let vat = 0;
		let grandTotal = 0;
		priceArr = cartData.filter(e => e.ischecked).map((item) => { return (item.employee_per_price === true) ? parseFloat(item.price) * parseInt(this.state.employeeCount) : item.price });
		total = priceArr && priceArr.length > 0 && priceArr.reduce((prev, curr) => { return prev + curr; });
		vat = total * (20 / 100);
		grandTotal = total + vat;
		return (
			<>
				<Header></Header>
				<ToastContainer position="top-right" autoClose={2500} closeOnClick />
				<section className="compare-hensive">
					<div className="container">
						<div className="price-compare">
							<h5>Simple and Comprehensive Pricing from</h5>
							<h2>£1 per Employee</h2>
							<p>We believe that a good customer relationship should be a part of every business. All of our  plans have been designed to fit your agency’s requirements.</p>
							{/* <button onClick={(e) => this.openSigninModal(e, '2')}>Try for Free</button> */}
							<Link to="/signup">Try for Free</Link>

						</div>
					</div>
				</section>
				{/* <section className="pricing-sections">
					<div className="board-pricing">
						<div className="middle-price">
							<div className="right-prc">
								<div className="empl-left">
									<h6> Enter Number of Employees </h6>
									<p> <span className="tooltip-spaces"> See how our affordable pricing works.
										<span className="tooltiptext-spaces">
											<button type="button" className="btn tool-close"> <i className="fa fa-times" aria-hidden="true"></i>
											</button>
											* The price will be based on the number of Employees <br />
											* The total price will be worked out based on this Formula (Shift Management + Modules) * No.of Employees
										</span>
									</span> </p>
									<h5> Select modules and view price below </h5>
								</div>
								<div className="empl-right">
									<input
										type="number"
										name="noOfEmp"
										step="1"
										min="1"
										className="btn btn-values"
										value={this.state.employeeCount}
										id="total-employees"
										onChange={this.onEmployeeChange}
										minLength={1}
										maxLength={3}
									/>
									<div className="count-valus">
										<button type="button" className="btn count-inc" onClick={e => this.incrementEmployee(e)}>
											<img className="sticker" src="../../../img/front/upper.svg" alt="img" />
										</button>
										<button type="button" className="btn count-dec" onClick={e => this.decrementEmployee(e)}>
											<img className="sticker" src="../../../img/front/lower.svg" alt="img" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						{planData && planData.length > 0 && <div className="price-widths">
							<div className="shifting-pricing">
								{planData.map((item, key) => {
									let cartCheck = cartData.findIndex((e) => { return item.name === e.name });
									return (
										<>
											<div className="choose-prc" key={key + 1} >
												<div onClick={e => this.checkdivitem(e, item)} className={`frontend-choice ${item.coming_soon === true ? "sing-color" : item.ischecked ? "mul-color" : 'no-color'}`}>
													{item.planIcon && <img className="plan-icon" src={`${NodeURL}/${item.planIcon}`} alt="img" />}
													{item.coming_soon === false && <img className="sticker" src="../../../img/front/sticker.svg" alt="img" />}
													<h5>
														{item.name}
													</h5>
													{item.coming_soon === false && <ul>
														{item.price > 0 && <li>
															<span className="left-count"> <img src={`${process.env.PUBLIC_URL}/img/front/price-amount.svg`} /> </span>
															<span className="right-count"> £{item.price} {item.employee_per_price === true ? "/ Emp /m" : " /m"}</span>
														</li>}
														{item.file_storage === true && <li>
															<span className="left-count">  <img src={`${process.env.PUBLIC_URL}/img/front/cloud.svg`} /> </span>
															<span className="right-count"> 5 GB </span>
														</li>}
													</ul>}
													{item.coming_soon === false && item.price > 0 && <div className="price-abs">
														<h2>
															<span> £ </span> {(item.employee_per_price === true) ? (this.state.employeeCount > 0) ? parseFloat(parseFloat(item.price) * parseInt(this.state.employeeCount)).toFixed(2) : parseFloat(parseFloat(item.price)).toFixed(2) : parseFloat(item.price).toFixed(2)}
														</h2>
													</div>}
													{item.coming_soon === true &&
														<ul>
															<li></li></ul>}
													{item.coming_soon === false &&
														<button type="button" className="btn common-spacs" onClick={(e) => this.openModuleModal(e, item)}>
															{" "} View Features{" "}
														</button>}
													{item.coming_soon === true && <div className="price-abs">
														<h2>
															<span className="cmg-sns"> Coming Soon..!</span>
														</h2>
													</div>}
												</div>
											</div>
											{this.state.hr_checked === true && item.file_storage === true && item.coming_soon === false ? <div className="choose-prc" key={key}> <div className={` frontend-choice  ${this.state.hr_checked === true ? "mul-color" : "sing-color sing-chs"} `}>
												<div className="sto-status">
													<div className="tooltip-stu">
														<span className="list-ques">!</span>
														<span className="tooltiptext-stu">
															Requires HR &amp; Compliance to unlock
														</span>
													</div>
												</div>
												<h5>
													{" "}
													Additional Storage{" "}
												</h5>
												<img className="sticker" src="../../../img/front/sticker.svg" alt="img" />
												<ul>
													<li>
														<span className="right-count">
															<button
																type="button"
																className="btn btn-adds"
																onClick={this.decrementCounter}
																disabled={!this.state.hr_checked}
															>
																-
															</button>
															<span className="count-btns">
																{" "}
																{this.state.counter} GB
															</span>
															<button
																type="button"
																className="btn btn-adds"
																onClick={this.incrementCounter}
																disabled={true}
															>
																+
															</button>
														</span>
													</li>
												</ul>
												<div className="price-abs">
													<h2>
														<span> £ </span> {item.file_size_amount.toFixed(2)}
													</h2>
												</div>
												<button type="button" className="btn common-spacs" onClick={(e) => this.openModuleModal(e, { 'name': 'Additional Storage' })}>
													{" "}
													View Features{" "}
												</button>
											</div></div> : null}
										</>
									)
								})}
							</div>
						</div>}
					</div>
					<Modal isOpen={this.state.pricesModels} toggle={this.openPricesModels} className={"cart-infoss " + this.props.className}>
						<ModalHeader>{" "} Subscription Fee Estimate{" "}
							<Button title="Close" onClick={this.openPricesModels}>
								<i className="fa fa-close" />
							</Button>{" "}
						</ModalHeader>
						<ModalBody>
							<div className="cart-varity">
								<div className="left-card-infos">
									<ul>
										<li className="cart-headings">
											<div className="mdl-const">
												<p>Module</p>
											</div>
											<div className="md2-const">
												<p>Price</p>
											</div>
											<div className="md3-const">
												<p>Total</p>
											</div>
										</li>
										<li>
											<div className="mdl-const">
												<p>Employees</p>
											</div>
											<div className="md2-const">
												<p>-</p>
											</div>
											<div className="md3-const">
												<p>{this.state.employeeCounts}</p>
											</div>
										</li>
										{cartData && cartData.length > 0 && cartData.filter(e => e.ischecked).map((item, i) => {
											return (
												<li key={i}>
													<div className="mdl-const">
														<p>{item.name}</p>
													</div>
													<div className="md2-const">
														<p>£ {(item.price).toFixed(2)}</p>
													</div>
													<div className="md3-const">
														<p>£ {(item.employee_per_price === true) ? (parseFloat(item.price) * parseInt(this.state.employeeCount)).toFixed(2) : item.price.toFixed(2)}</p>
													</div>
												</li>
											)
										})}
									</ul>
								</div>
								<div className="right-card-infos">
									<div className="list-ment">
										<h4> Price Details </h4>
										<ul>
											<li>
												<span className="ths-list">Price</span>
												<span>£ {Number(total).toFixed(2)}</span>
											</li>
											<li>
												<span className="ths-list">VAT (10%)</span>
												<span>£ {Number(vat).toFixed(2)}</span>
											</li>
											<li className="tls-prc">
												<span className="ths-list">Total</span>
												<span>£ {Number(grandTotal).toFixed(2)}</span>
											</li>
										</ul>
									</div>
									<div className="prices-cont">
										<button type="button" className="btn btn-const" onClick={(e) => this.openSigninModal(e, '2')}>
											{" "} Try for free{" "}
										</button>
									</div>
								</div>
							</div>
						</ModalBody>
					</Modal>
					<Modal isOpen={this.state.moduleModal} toggle={this.openModuleModal} className={"price-mains " + this.props.className}>
						<ModalHeader>
							{" "} {activeFeature.name}
							<Button title="Close" onClick={this.openModuleModal}>
								<i className="fa fa-close" />
							</Button>{" "}
						</ModalHeader>
						<ModalBody>
							{activeFeature.name === 'Shift Management' && <div className="feature-maintains">
								<div className="feature-lists">
									<h6>Simple, intuitive online employee scheduling software & employee app</h6>
									<ul>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Shift
											Calendar{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Map
											View{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Employee Distance Algorithm{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Email
											Templates{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Shifts
											Export{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Multiple Client Work Locations{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Shift
											Templates{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Coverage Set Up{" "}
										</li>
									</ul>
								</div>
								<div className="feature-lists">
									<h6>Job Roles & Staff Rates</h6>
									<ul>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Job
											Roles{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Agency
											Role Rates{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Employee Role{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Individual Employee Rates{" "}
										</li>
									</ul>
								</div>
							</div>}
							{activeFeature.name === 'Invoicing' && <div className="feature-maintains">
								<div className="feature-lists">
									<h6>The easy way to stay on top of invoicing without lifting a finger
										Create professional, customised invoices automatically from e-timesheets and get paid faster.</h6>
									<ul>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Automated invoicing{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Easy to edit{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> {" "}
											Create manual invoice{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Smooth timesheet attachments{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> VAT function{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Pay-in invoices{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Late booking fee option{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>
											Downloadable pdf{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Manual timesheet attachments{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Click to email{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>
											Send Reminders{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Convenient Client Portal{" "}
										</li>
									</ul>
								</div>
							</div>}
							{activeFeature.name === 'Time & Attendance Tracker' && <div className="feature-maintains">
								<div className="feature-lists">
									<h6>Record accurate timesheets for perfect payroll, see live employee attendance, and make sure your
										staff get the breaks they need.</h6>
									<ul>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Employee tracking{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Time Tracking{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> {" "}
											Clock in/Clock out{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Geofencing{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Shift Start/Stop Reminders{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Restricted start time{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Shift Progress bar{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>
											Shift Auto-start/Auto-stop{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Automated Timesheet{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Click to email{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>
											Attendance monitoring{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Payroll Export{" "}
										</li>
									</ul>
								</div>
							</div>}
							{activeFeature.name === 'HR & Compliance Management' && <div className="feature-maintains">
								<div className="feature-lists">
									<h6>Stay on top of compliance with your staff documents in one place</h6>
									<ul>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> HR document storage{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Safe secure cloud storage{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> {" "}
											Complete filing system{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Staff portal{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Detailed Employee Profile{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Staff profiles download{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Staff profile sharing{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>
											Employee Notifications{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Compliance reminder notifications{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Employees by area{" "}
										</li>
									</ul>
								</div>
							</div>}
							{activeFeature.name === 'Additional Storage' && <div className="feature-maintains">
								<div className="feature-lists">
									<h6>Get all the storage capacity that you need. Store, access and share your files in one secure place</h6>
									<ul>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Organise employee files{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Access your files anywhere{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> {" "}
											Find what you need fast{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Centralise your business information{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Collaborate easily{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Restore content{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Share easily{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>
											Data loss prevention{" "}
										</li>
									</ul>
								</div>
							</div>}
							{activeFeature.name === 'Recruitment Application Tracking' && <div className="feature-maintains">
								<div className="feature-lists">
									<h6>Automate your workflow and stay in control of your recruitment process, give candidates a
										delightful digital experience and convert them faster. </h6>
									<ul>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Registration Forms{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Application Form{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> {" "}
											Candidate visibility{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Role categorisation{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Printable forms{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Automated Referencing{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Compliance tracking{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>
											Interviewing Simplified{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Document handling{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Checks Management{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>
											Hustle free verification{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Documents & E-Signatures{" "}
										</li>
									</ul>
								</div>
							</div>}
							{(activeFeature.name === 'Email System' || activeFeature.name === 'Communication') && <div className="feature-maintains">
								<div className="feature-lists">
									<h6>Stay in touch with your employees and clients at the click of a button</h6>
									<ul>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Email tool with bulk Email{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> SMS tool with bulk SMS{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> {" "}
											Broadcast Shifts{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> App publishing{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Email templates{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											SMS templates{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Timed app publications{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>
											Shift Notifications{" "}
										</li>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i>{" "}
											Automated reminders{" "}
										</li>
									</ul>
								</div>
							</div>}
							{activeFeature.name === 'CRM' && <div className="feature-maintains">
								<div className="feature-lists">
									<h6>CRM</h6>
									<ul>
										<li>
											<i className="fa fa-check" aria-hidden="true"></i> Coming Soon{" "}
										</li>
									</ul>
								</div>
							</div>}
						</ModalBody>
					</Modal>
				</section> */}
				{/* {this.state.showPriceDetail === true ? <section className="subsecription-fees">
					<dv className="container">
						<div className="cart-varity">
							<div className="left-card-infos">
								<ul>
									<li className="cart-headings">
										<div className="mdl-const">
											<p>Module</p>
										</div>
										<div className="md2-const">
											<p>Quantity</p>
										</div>
										<div className="md2-const">
											<p>Price</p>
										</div>
										<div className="md3-const">
											<p>Total</p>
										</div>
									</li>
									<li>
										<div className="mdl-const">
											<p>Employees</p>
										</div>
										<div className="md2-const">
											<p>{this.state.employeeCount}</p>
										</div>
										<div className="md3-const">
											<p>-</p>
										</div>
									</li>
									{cartData && cartData.length > 0 && cartData.filter(e => e.ischecked).map((item, j) => {
										return (
											<li key={j}>
												<div className="mdl-const">
													<p>{item.name}</p>
												</div>
												<div className="md2-const">
													<p> {item.employee_per_price ? this.state.employeeCount : `-`} </p>
												</div>
												<div className="md2-const">
													<p>£ {(item.price).toFixed(2)}</p>
												</div>
												<div className="md3-const">
													<p>£ {(item.employee_per_price === true) ? (parseFloat(item.price) * parseInt(this.state.employeeCount)).toFixed(2) : item.price.toFixed(2)}</p>
												</div>
											</li>
										)
									})}
								</ul>
							</div>
							<div className="right-card-infos">
								<div className="list-ment">
									<ul>
										<li>
											<h4> Price Details </h4>
										</li>
										<li>
											<span >Price</span>
											<span className="ths-list">£ {Number(total).toFixed(2)}</span>
										</li>
										<li>
											<span>VAT (10%)</span>
											<span className="ths-list">£ {Number(vat).toFixed(2)}</span>
										</li>
										<li className="tls-prc">
											<span>Total</span>
											<span className="ths-list">£ {Number(grandTotal).toFixed(2)}</span>
										</li>
									</ul>
								</div>
								<div className="prices-cont">
									<button type="button" className="btn btn-const" onClick={(e) => this.openSigninModal(e, '2')}>
										{" "} Try for free{" "}
									</button>
								</div>
							</div>
						</div>
					</dv>
				</section> : null} */}
				{/* {this.state.showTotalPrice === true ? <section className="price-banking">
					<div className="container">
						<div className="breakup-price">
							<div className="left-brns">
								<h6>
									<span className="dtep-totl"> Total </span>
									<span className="dtep-tot2"><span className="dtep-price"> £ </span>  {Number(grandTotal).toFixed(2)} </span>
									<span className="dtep-tot3" style={{ cursor: 'pointer' }} onClick={e => this.showPriceDetailView(e)}> View Price Breakdown </span>
								</h6>
							</div>
							<div className="right-brns">
								<button type="button" className="buybtns-ops" onClick={e => this.openSigninModal(e, '2')}>
									Try for free
								</button>
							</div>
						</div>
					</div>
				</section> : null} */}
				{this.state.showLoginModal === true ? <AuthetiCationModal
					loginModal={this.state.showLoginModal}
					closeModal={e => this.closeSigninModal(e)}
					autheticateTab={this.state.autheticateTab}
				/> : null}
				<Footer></Footer>
			</>
		);
	}
}
